<template>
    <h-row justify="center">
        <h-col cols="11" md="6">
            <h-row>

                <h-col cols="12" class="pb-0">
                    <h1>H2H - Versione {{ current.version }}</h1>
                </h-col>

                <h-col cols="12" class="pt-0">
                    <i>{{ current.data }}</i>
                </h-col>

                <h-col cols="12" v-for="(group, index) in current.group" :key="index">

                    <h1>&rarrhk; {{ group.id }}</h1>

                    <ul class="ml-6">
                        <li v-for="(item, index) in group.items" :key="index">
                            <i>{{ item }}</i>
                        </li>
                    </ul>

                </h-col>

            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';

    export default {
        name: 'Changelog',
        components: {HRow, HCol},
        data: () => ({
            groups: {
                ADDED: 'Aggiunte',
                CHANGED: 'Cambiamenti',
                FIXED: 'Fix',
            }
        }),
        computed: {
            version: function () {
                return this.$route.params.version;
            },
            current: function () {

                let current = this.data.find(d => d.version === this.version);
                current = current ? current : this.data[0];

                return current;
            },
            data: function () {

                return [
                    {
                        version: '1.0.0',
                        data: '28 Febbraio 2022',
                        group: [
                            {
                                id: this.groups.ADDED,
                                items: [
                                    'Nuova sezione "NEWS" dove è possibile trovare eventi casuali suggeriti da noi',
                                    'Nuova sezione "SEGNALIBRI" per salvare e raggiungere gli eventi più facilmente'
                                ]
                            },
                            {
                                id: this.groups.CHANGED,
                                items: [
                                    'Il cambiamento della lingua è stato spostato direttamente nelle impostazioni dell\'app'
                                ]
                            },
                            {
                                id: this.groups.FIXED,
                                items: [
                                    'Corretto problema sul cambio della lingua che portava al riavvio involontario dell\'app'
                                ]
                            }
                        ]
                    }
                ];

            }
        }
    }
</script>

<style scoped>

</style>
